(function () {
/* eslint-disable no-console */
'use strict';

angular.module('hyresgastApp').controller('ShowAgreementCtrl', ShowAgreementCtrl);

function ShowAgreementCtrl($scope, $uibModal, $routeParams, $route, $cookies, $timeout, store,
  Auth, Flash, SubPage, ErrorHandler, HyUtils,
  Agreement, FreeAgreement, Negotiation, Seenby,
  NegotiationTypes, NegotiationStates, NegotiationRoles, Parties, AgreementTypes, FreeAgreementTypes, ObjectTypes) {

  var vm = this;

  vm.findNegotiationRole = HyUtils.findNegotiationRole;
  vm.NegotiationStates = NegotiationStates;
  vm.NegotiationTypes = NegotiationTypes;
  vm.FreeAgreementTypes = Object.values(FreeAgreementTypes);
  vm.AgreementTypes = AgreementTypes;

  vm.downloadPDF = downloadPDF;
  vm.previewPDF = previewPDF;
  vm.toggleAttachmentsPane = toggleAttachmentsPane;
  vm.getNegotiation = getNegotiation;
  vm.getAgreement = getAgreement;
  vm.saveAgreement = saveAgreement;
  vm.signStart = signStart;
  vm.pullOut = pullOut;
  vm.signDrop = signDrop;
  vm.getScriveStatus = getScriveStatus;
  vm.alterRole = alterRole;
  vm.toggleCheck = toggleCheck;
  vm.toggleAll = toggleAll;
  vm.isPageLoading = isPageLoading;

  vm.addVoidUser = addVoidUser;
  vm.deleteVoidUser = deleteVoidUser;

  vm.changeFreeParticipants = changeFreeParticipants;
  vm.changeFreeAgreementType = changeFreeAgreementType;
  vm.setEndDate = setEndDate;

  vm.negotiation = { id: $routeParams.negotiationId };
  vm.participants = [];
  vm.canStartSigning = false;
  vm.canDownloadPDF = false;
  vm.skipRentlist = false;

  Auth.heartbeat().then(function() {
    vm.currentUser = Auth.currentUser();
    let negotiationId = $routeParams.negotiationId;
    let agreementId = $routeParams.id;

    vm.isFreeAgreement = !negotiationId;
    vm.Resource = vm.isFreeAgreement ? FreeAgreement : Agreement;

    getCurrentSubpage();
    if (!vm.isFreeAgreement) {
      getNegotiation(negotiationId);
    }
    getAgreement(negotiationId, agreementId);
    getScriveStatus(negotiationId, agreementId);
  });

  ////////
  const EXTERNAL_TITLES = [
    'Ombud för hyresvärd, enligt fullmakt',
    'Fastighetsägarna (part i 3-parts FO)',
    'Ombud för Fastighetsägarna, enligt fullmakt (3-parts FO)',
    'Ombud för hyresvärd och Fastighetsägarna, enligt 2 fullmakter (3-parts FO)',
    'Förtroendevald Hyresgästföreningen'
  ];
  const STUCK_AGREEMENT = 'bubkis';

  function alterRole(party) {

    var userParty = Object.values(NegotiationRoles)
      .find(nr => nr.code === party.roleObj.code).party;

    var possibleRoles = Object.values(NegotiationRoles)
      .filter(nr => nr.party === userParty && !nr.isChief)
      .map(nr => nr.name);

    switch(userParty) {
      case Parties.HGF:
        //no need for Hyresgästföreningen
        possibleRoles.splice(0, 1);
        possibleRoles.push('Ordförande förhandlingsdelegation');
        break;
      case Parties.EXTERNAL:
        Array.prototype.push.apply(possibleRoles, EXTERNAL_TITLES);
        break;
    }

    $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075,
      templateUrl: 'resources/agreement/show/overview/alterRole.html',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      controller: 'AlterRoleModalCtrl',
      controllerAs: 'vm',
      resolve: {
        selectedRole: () => party.roleObj.alternateRole,
        possibleRoles: () => possibleRoles
      }
    }).result.then(function(res) {
      party.roleObj.alternateRole = res;
      saveCookies();
    });
  }

  function toggleCheck() {
    vm.noParticipantsSelected = !vm.participants.reduce((a, p) => a = p.selected ? true : a, false);
    saveCookies();
  }

  function toggleAll() {
    var state = vm.participants.reduce((a, p) => a = !p.selected ? false : a, true);
    vm.participants.forEach(p => p.selected = !state);
    vm.toggleCheck();
  }

  function getNegotiation(negotiationId) {
    Negotiation.get({ id: negotiationId })
      .$promise.then(function (response) {
        vm.negotiation = response;

        vm.isNewlyConstructedProperty = vm.negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY;
        vm.isNewlyConstructedApartment = vm.negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT;
        vm.negotiation.isFinished = vm.negotiation.state === NegotiationStates.FINISHED ||
                                    vm.negotiation.state === NegotiationStates.FINISHED_OUTSIDE_PORTAL;
        vm.counterpart = vm.negotiation.counterpart;
        setupUI();
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(() => { vm.readyNegotiation = true });
  }

  /**
   * retrieves agreement
   * @param {number} agreementId
   */
  function getAgreement(negotiationId, agreementId) {
    vm.Resource.get({
      agreementId,
      negotiationId,
      id: agreementId,
    }).$promise.then(function (response) {
      vm.agreement = response.agreement ? response.agreement : response;
      //vm.agreementOwners = vm.agreement.owners.map(x => `<b>${x.organizationNumber}, ${x.name}</b>`).join(', <br/>');
      vm.stuckAgreement = vm.agreement.scriveId === STUCK_AGREEMENT;
      if (vm.agreement.conditionTags) {
        let conditionTags = JSON.parse(vm.agreement.conditionTags);
        vm.isPresumtiveRent = conditionTags.isPresumtiveRent;
      }
      if (vm.isFreeAgreement) {
        if (vm.agreement.invites) {
          vm.agreement.invites.forEach(p => p.roleObj = vm.findNegotiationRole(p.role));
        }
        vm.freeAgreementType = vm.FreeAgreementTypes.find(t => t.code === vm.agreement.type);
      }
      if (vm.agreement.releaseDate) {
        vm.agreement.releaseDate = new Date(vm.agreement.releaseDate);
      }
      if (vm.agreement.rentEffectiveFrom) {
        vm.agreement.rentEffectiveFrom = new Date(vm.agreement.rentEffectiveFrom);
      }
      vm.skipRentlist = vm.agreement.type === AgreementTypes.AGREEMENT_WITHOUT_RENTLIST;
      getDocuments(negotiationId, agreementId);
      setupUI();
    })
      .catch(new ErrorHandler('Misslyckades att hämta överenskommelse.'))
      .finally(function () {
        vm.ready = true;
        Seenby.set({
          objectType: ObjectTypes.AGREEMENT.code,
          objectId: $routeParams.id,
        });
      });
  }

  function getDocuments(negotiationId, agreementId) {
    vm.Resource.queryDocument({
      id: agreementId,
      agreementId,
      negotiationId
    }).$promise.then(response => {
      vm.agreement.documents = response;
      vm.agreement.numDocuments = vm.agreement.documents ? vm.agreement.documents.length : 0;
    }).catch(new ErrorHandler('Misslyckades att hämta dokument'))
      .finally(() => { vm.readyDocument = true });
  }

  function getCurrentSubpage() {
    var defaultSubPage = 'overview';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['properties', 'documents'];
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }

  function setupUI() {
    if (!vm.agreement) { return; }
    if (!vm.isFreeAgreement && !vm.negotiation.callId) { return; }
    prepareParticipants();
    setPermissions();
    if (vm.agreement.counterpart) {
      vm.counterpart = vm.agreement.counterpart;
      setCounterpartNumber();
    } else if (vm.negotiation.counterpart) {
      vm.counterpart = vm.negotiation.counterpart;
      setCounterpartNumber();
    }
  }

  function prepareParticipants() {
    //get zey ale
    if (!vm.isFreeAgreement) {
      vm.participants = vm.negotiation.hgfParticipants
        .concat(vm.negotiation.externalParticipants)
        .concat(vm.negotiation.delegationParticipants);
      vm.participants.forEach(x => x.canBeDeleted = x.disabled);
      let agreementUsers = vm.agreement.users;
      agreementUsers.forEach(x => x.canBeDeleted = true);
      vm.participants = vm.participants.concat(agreementUsers);
    } else {
      vm.participants = vm.agreement.users;
      vm.participants.forEach(x => x.canBeDeleted = x.disabled);
    }

    //set roles
    vm.participants.forEach(p => {
      p.selected = true;
      let role = p.negotiationsUsers ? p.negotiationsUsers.role : p.agreementRole;
      let roleObj = vm.findNegotiationRole(role);
      if (roleObj) {
        //findNegotiationRole returns reference, but we need value here
        p.roleObj = JSON.parse(JSON.stringify(roleObj));
      } else {
        // eslint-disable-next-line no-undef
        console.log('oops, unknown role');
      }
    });

    vm.participants = vm.participants.filter(p => p.roleObj);

    vm.participants = vm.participants.sort((a, b) => b.roleObj.party - a.roleObj.party);

    //group parties
    var prevP = vm.participants[0];
    vm.participants.forEach(p => {
      if(p.roleObj.party !== prevP.roleObj.party) {
        p.groupBreak = true;
      }
      prevP = p;
    });

    restoreCookies();
  }

  function setCounterpartNumber() {
    vm.counterpartNumber = vm.counterpart.organizationNumber.substring(0, 6) + '-' +
                           vm.counterpart.organizationNumber.substring(6, 10);
  }

  function setPermissions() {

    var currentUserInNegotiation = vm.negotiation.id
      ? vm.participants.find(p => p.id === vm.currentUser.id)
      : vm.participants.find(p => p.userId === vm.currentUser.id);

    if (currentUserInNegotiation) {
      var currentUserRole = currentUserInNegotiation.roleObj.code;

      if ((currentUserRole !== NegotiationRoles.HGF_ELECTED.code) &&
          (currentUserRole !== NegotiationRoles.HGF_ELECTED_CHIEF.code)) {
        vm.canDownloadPDF = true;
        vm.canStartSigning = true;
      }
    }
  }

  function formatDate(date) {
    if (!date) {
      return null;
    }
    return date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
  }

  function saveAgreement(callback) {
    if (!callback) {
      vm.saving = true;
    }
    vm.agreement.releaseDate = formatDate(vm.agreement.releaseDate);
    vm.agreement.rentEffectiveFrom = formatDate(vm.agreement.rentEffectiveFrom);
    vm.agreement.agreementId = vm.agreement.id;
    // Sequelize save do not perform custom actions, neiter do users change, so we preserve users for performance sake
    var tempUsers = vm.agreement.users;
    FreeAgreement.save(vm.agreement)
      .$promise.then(
        function (response) {
          vm.agreement = response;
          vm.agreement.users = tempUsers;
          vm.agreement.rentEffectiveFrom = new Date(vm.agreement.rentEffectiveFrom);
          if (vm.agreement.releaseDate) {
            vm.agreement.releaseDate = new Date(vm.agreement.releaseDate);
          }
          getDocuments(vm.negotiation.id, vm.agreement.id);

          callback();
        },
        function (reason) { // eslint-disable-line no-unused-vars
          Flash.set('Misslyckades att spara', 'error');
          getAgreement();
        }
      )
      .finally(function () {
        if (!callback) {
          vm.saving = false;
        }
      });
  }

  function getScriveStatus(negotiationId, agreementId) {

    return vm.Resource.getScriveStatus({
      negotiationId: negotiationId,
      agreementId: agreementId,
    }).$promise.then(function (response) {

      vm.signStartVisible = false;
      vm.signStatusVisible = false;
      vm.signDownloadVisible = false;
      vm.signDropAvailable = false;

      if (!response.scriveResponse) {
        /**
         * we have not started scrive process yet.
         * no id in the database, no pdf uploaded to scrive.
         * lets do it
         */
        if (response.mandatoryRolesPresent) {
          vm.signStartVisible = true;
        } else {
          vm.signError = 'Kan inte börja signera. Inte alla nödvändiga roller närvarande';
          vm.signErrorVisible = true;
        }

      } else {
        /**
         * we have document in scrive for that agreement
         */

        if (response.scriveResponse.status.status) {
          var status = response.scriveResponse.status.status;
          vm.rejectedReactionsVisible = false;

          if (status.indexOf('The resource was not found. A document with id') !== -1) {
            status = 'Dokumentet laddas upp, men statusen är okänd';
          }

          if (status==='rejected') {
            let rejectedEvent = response.scriveResponse.history.events.find(e => e.status === 'rejected');
            status = `<b>[${rejectedEvent.party}]:</b> ${rejectedEvent.text}`;

            vm.rejectedReactionsVisible = true;
          }

          vm.signDropAvailable = status !== 'closed';

          vm.scriveStatus = status;
          vm.signStatusVisible = true;
        }

        if (response.scriveResponse.status.parties) {
          vm.scriveParties = response.scriveResponse.status.parties.slice(1).reduce(
            function (accumulator, party) {
              let roleObj = vm.participants.find(x => x.email === party.email).roleObj;
              return accumulator +
                '<div class="inline-block--55">' +
                ((party['sign_time']) ? '<b>' : '<i>') +
                party.fields[0].value + ' ' +
                party.fields[1].value +
                ((party['sign_time']) ? '</b>' : '</i>') +
                ' (' + party.fields[2].value + ') ' +
                '</div>' +
                (roleObj.alternateRole ? roleObj.alternateRole : roleObj.name) +' <br/>';
            }, '');
        }

        vm.signDownloadVisible = true;
      }
    }).catch(e => {
      if (e.status === 501) {
        ErrorHandler('Misslyckades att efterbehandla dokument.');
      } else {
        ErrorHandler('Misslyckades att hämta Scrive status.');
      }
    }).finally(function () {
      vm.readyScrive = true;
    });
  }

  function decideAboutConversionErrors(conversionErrors) {
    $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075,
      templateUrl: 'resources/agreement/show/conversion-errors-modal.html',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      controller: 'ConversionErrorsModalCtrl',
      controllerAs: 'vm',
      resolve: {
        conversionErrors: () => conversionErrors
      }
    }).result.then(() => signStart(true));
  }

  function signStart(skipConversionErrors) {
    if (!skipConversionErrors) {
      HyUtils.askQuestion('Vill du visa utgående Normhyra i överenskommelsen?', null, 2)
        .then(result => {
          let insertNH = (result === 'Ja');
          vm.saving = true;
          saveAgreement(() => {
            return vm.Resource.signAgreement({
              negotiationId: vm.negotiation && vm.negotiation.id,
              agreementId: vm.agreement.id,
              selectedParticipants: JSON.stringify(vm.participants.filter(p => p.selected)),
              insertNH,
              skipConversionErrors,
            }).$promise.then(response => {  // eslint-disable-line no-unused-vars
              vm.signStartVisible = false;
              vm.signDownloadVisible = true;
              vm.saving = false;
              $timeout(() => getScriveStatus(vm.negotiation && vm.negotiation.id, vm.agreement.id), 1000);
            }).catch(e => {
              if (e.status === 400) {
                decideAboutConversionErrors(e.data.conversionErrors);
              } else {
                ErrorHandler('Misslyckades att börja signera.');
              }
            }
            ).finally(() => { vm.saving = false });
          });
        });
    }
  }

  function pullOut() {
    vm.saving = true;

    vm.Resource.pullOut({ agreementId: vm.agreement.id})
      .$promise.then(() => { $route.reload() });
  }

  function signDrop() {
    vm.saving = true;

    return vm.Resource.dropAgreement({
      negotiationId: $routeParams.negotiationId,
      agreementId: vm.agreement.id
    }).$promise.then(response => {  // eslint-disable-line no-unused-vars
      $route.reload();
    })
      .finally(function () {
        vm.saving = false;
      });
  }

  function toggleAttachmentsPane() {
    if (!vm.showAgreementsPane && !vm.signStatusVisible) {
      saveAgreement();
    }
    vm.showAgreementsPane = !vm.showAgreementsPane;
  }

  function previewPDF() {
    vm.saving = true;
    vm.Resource.getPreview({
      negotiationId: vm.negotiation && vm.negotiation.id,
      agreementId: vm.agreement.id,
      selectedParticipants: JSON.stringify(vm.participants.filter(p => p.selected)),
      skipConversionErrors: true,
      isPreview: true,
    }).$promise.then(function(response) {
      let filename = `Förhandsvisning_${vm.agreement.id}.pdf`;
      HyUtils.spitOutAttachment(response.data, filename, 'application/pdf');
      vm.saving = false;
    });
  }

  function downloadPDF() {
    vm.saving = true;
    vm.Resource.getSignedAgreement({
      negotiationId: $routeParams.negotiationId,
      agreementId: vm.agreement.id
    }).$promise.then(function(response) {
      let filename = `Överenskommelse_${vm.agreement.id}.pdf`;
      HyUtils.spitOutAttachment(response.data, filename, 'application/pdf');
      vm.saving = false;
    });
  }

  function saveCookies() {
    let state = vm.participants.map(p => ({
      id: p.id,
      alternateRole: p.roleObj.alternateRole,
      selected: p.selected
    }));

    $cookies.put('agreementState'+$routeParams.id, JSON.stringify(state));
    store.set('agreementAttachment'+$routeParams.id, vm.attachment);
  }

  function restoreCookies() {
    let cookie = $cookies.get('agreementState'+$routeParams.id);
    if (cookie) {
      let checkboxState = JSON.parse(cookie);
      if (checkboxState) {
        checkboxState.forEach(cs => {
          let participant = vm.participants.find(p => p.id === cs.id);
          if (participant) {
            participant.selected = cs.selected;
            participant.roleObj.alternateRole = cs.alternateRole;
          }
        });
      }
    }
  }

  function changeFreeParticipants() {
    $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075,
      templateUrl: 'resources/agreement/free/change-participants-modal.html',
      windowClass: 'modal-content--white-border',
      backdrop: 'static',
      controller: 'NewFreeAgreementModalCtrl',
      controllerAs: 'vm',
      resolve: {
        users: () => vm.agreement.users,
        invites: () => vm.agreement.invites,
      }
    }).result.then(result => {
      FreeAgreement.changeParticipants({
        agreementId: vm.agreement.id,
        userContainer: result.userContainer,
      }).$promise.then(() => $route.reload());
    });
  }

  function changeFreeAgreementType (selection) {
    vm.freeAgreementType = selection;
    vm.agreement.type = selection.code;
  }

  function setEndDate (selection) {
    var d = new Date(vm.agreement.rentEffectiveFrom);
    d.setFullYear(d.getFullYear() + selection);
    vm.agreement.releaseDate = d;
  }

  function isPageLoading() {
    return !(vm.ready && (vm.isFreeAgreement || vm.readyNegotiation) && vm.readyScrive);
  }

  function addVoidUser() {
    FreeAgreement.addVoidUser(Object.assign({ id: vm.agreement.id }, vm.voidUser))
      .$promise.then(() => { $route.reload() });
  }

  function deleteVoidUser(participant) {
    let message = 'Är du säker?';

    HyUtils.showDefaultModal('ConfirmationModalWithMessageCtrl',
      'components/modal/confirmation/confirmationModalWithMessage.html',
      // eslint-disable-next-line no-unused-vars
      { message }, result => {
        FreeAgreement.deleteVoidUser({ id: vm.agreement.id, userId: participant.userId })
          .$promise.then(() => { $route.reload() });
      }, null, null, { size: 'xsm' });
  }
}

})();
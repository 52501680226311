(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationCallDocumentsCtrl', ShowNegotiationCallDocumentsCtrl);

function ShowNegotiationCallDocumentsCtrl(
  $routeParams, $uibModal,
  NegotiationCall,
  ValidFileTypes, CommonMessages,
  HyUtils, Flash, Auth, ErrorHandler) {
  var vm = this;
  vm.add = add;
  vm.delete = destroy;
  vm.Auth = Auth;

  /////////

  getDocuments();

  function getDocuments() {
    vm.readyDocuments = false;

    return NegotiationCall.listDocuments({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.files = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta underlag.'))
      .finally(function() {
        vm.readyDocuments = true;
      });
  }

  function add() {
    var settings = {
      title: 'Ladda upp underlag',
      uploadUrl: '/api/negotiation-calls/' + $routeParams.id + '/documents',

      // valid extension to upload
      validFileTypes: ValidFileTypes,

      // a file is associated with one type
      types: [
        { id: 13, name: 'Inlaga' },
        { id: 1, name: 'Allmänna ekonomiska läget' },
        { id: 2, name: 'Budget kommande år' },
        { id: 3, name: 'Budget/utfall föregående år' },
        { id: 4, name: 'Förvaltningskvalitet och service' },
        { id: 5, name: 'Läge och standard' },
        { id: 6, name: 'Medlems- och kundundersökning' },
        { id: 7, name: 'Prognos för innevarande år' },
        { id: 8, name: 'Underhållsplan' },
        { id: 9, name: 'Övrigt' }
      ],
      typeTitle: 'Välj typ av underlag'
    };

    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'components/file-upload-ex-modal/file-upload-ex-modal.html',
      controller: 'FileUploadExModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });

    modalInstance.result.then(function(files) {
      vm.files.push(...files);
      Flash.set('Lyckades lägga till underlag', 'success');
    });
  }

  function destroy(file) {
    HyUtils.showDefaultModal('DeletionConfirmationModalCtrl',
      'components/modal/confirmation/deletionConfirmationModal.html',
      { message: CommonMessages.FILE_DELETE_CONFIRMATION },
      // eslint-disable-next-line no-unused-vars
      result => {
        NegotiationCall.deleteDocument({
          id: $routeParams.id,
          blobId: file.name
        // eslint-disable-next-line no-unused-vars
        }).$promise.then(function(response) {
          var index = vm.files.indexOf(file);
          vm.files.splice(index, 1);
          Flash.set('Lyckades ta bort underlag', 'success');
        }).catch(new ErrorHandler('Misslyckades att ta bort underlag.'));
      }, null, null, { size: 'md' });


  }
}

})();
(function () {
'use strict';

angular.module('hyresgastApp').controller('OwnersListCtrl', OwnersListCtrl);

function OwnersListCtrl($scope, $location, $filter,
  RealEstate) {
  var vm = this;

  //TODO: on second usage of regionId extract this to some constant
  let regions = [1, 2, 3, 4, 6, 7, 8, 9, 10].map(id => ({
    id,
    name: $filter('regionTypeToDescription')(id)
  }));

  vm.tableColumns = [
    {
      title: 'Region',
      column: 'regionId',
      lookup: {
        idProperty: 'id',
        textProperty: 'name',
        dictionary: regions,
      },
    },
    {
      title: 'Namn',
      column: 'name',
    },
    {
      title: 'Organisationsnummer',
      column: 'organizationNumber',
    }
  ];
  vm.tableFGetData = RealEstate.queryOwners;

  vm.gotoOwner = owner => {
    $location.path('/properties').search({ owners: owner.name });
  };
}
})();
(function () {
'use strict';

//TODO: flytta til fe+common

angular.module('hyresgastApp').filter('hyStatusString', function(ApartmentStatusTypes) {
  return function(value, statusSet) {
    let validStatusTypes = Object.values(ApartmentStatusTypes);

    switch (statusSet) {
      case 1:
        validStatusTypes = validStatusTypes
          .filter(x => !['P', 'E', 'F', 'M', '10m'].includes(x.name));
        break;
    }

    return validStatusTypes
      .filter(s => (value & s.code))
      .map(s => s.colouredName || s.name)
      .join(', ');
  };
});


})();
(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationMotivCtrl', ShowNegotiationMotivCtrl);

function ShowNegotiationMotivCtrl(
  $scope,
  $location,
  $timeout,
  $uibModal,
  $routeParams,
  Auth,
  Flash,
  Negotiation,
  RealEstate,
  ErrorHandler
) {
  // ------------------------------------------------------------------------------------------------------------
  // In many places in this file, we deal with objects that can be either a property (fastighet) or an apartment.
  // For historical reasons, in most places the variables used are named as if these objects are always properties.
  // ------------------------------------------------------------------------------------------------------------
  var vm = this;
  vm.parentCtrl = $scope.$parent.vm;
  vm.selectedPropertyIds = {};
  vm.readyOwners = {};
  vm.getPropertyOwners = getPropertyOwners;
  vm.editDemandAndMotivation = editDemandAndMotivation;
  vm.realEstates = [];
  vm.queryRealEstate = queryRealEstate;

  vm.pageLimit = 30;
  vm.currentPage = 0;
  vm.firstRecordInCurrentPage = 1;

  vm.sortColumn = $location.search().order || 'propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  $scope.$on('$routeUpdate', function () {
    if ($location.search().order) {
      // reset offset when sorting
      queryRealEstate();
    }
  });

  vm.parentCtrl.negotiationQueryPromise.then(function () {
    if (vm.parentCtrl.apartmentMode) {
      vm.listColumns = [{
          title: 'Fastighetsbeteckning',
          column: 'propertyDesignation'
        },
        {
          title: 'Gatuaddress',
          column: 'streetAddress'
        },
        {
          title: 'Nationella lägenhetsnr',
          column: 'nationalNo'
        },
        {
          title: 'Lägenhetsnr',
          column: 'ownerNo'
        },
        {
          title: 'Kommun',
          column: 'municipality'
        },
        {
          title: 'Fastighetsägare',
          column: 'primaryOwner.name'
        }
      ];
    } else {
      vm.listColumns = [{
          title: 'Fastighetsbeteckning',
          column: 'propertyDesignation'
        },
        {
          title: 'Kommun',
          column: 'municipality'
        },
        {
          title: 'Antal lägenheter',
          column: 'numNegotiatedApartments'
        },
        {
          title: 'Fastighetsägare',
          column: 'primaryOwner.name'
        }
      ];
    }

    return queryRealEstate();
  });

  ////////////

  function closeAllOwnersLists() {
    for (var key in vm.toggleOwnersList) {
      vm.toggleOwnersList[key] = false;
    }
  }

  function queryRealEstate(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.pageOffset = 0;
    }
    closeAllOwnersLists();

    if (vm.parentCtrl.apartmentMode) {
      return Negotiation.queryApartment({
          id: $routeParams.id,
          order: vm.sortColumn,
          direction: vm.sortDirection,
          'search[property.propertyDesignation]': vm.inputTextProperty,
          'search[streetAddress]': vm.inputTextStreetAddress,
          'search[nationalNo]': vm.inputTextNationalNumber,
          'search[ownerNo]': vm.inputTextApartmentNumber,
          'search[municipality]': vm.inputTextMunicipality,
          'search[ownerName]': vm.inputTextOwner,
          include: ['demands', 'owners']
        }, {})
        .$promise.then(function (response) {
          vm.realEstates = response.rows;
          vm.totalCount = response.count;
          // vm.rentFile = response.rentFile;
          initializeCheckboxList();
        })
        .catch(ErrorHandler('Misslyckades att hämta lägenheter.'))
        .finally(function () {
          vm.ready = true;
          vm.readyProgressCircular = true;
        });
    } else {
      return Negotiation.queryProperty({
          id: $routeParams.id,
          order: vm.sortColumn,
          direction: vm.sortDirection,
          'search[propertyDesignation]': vm.inputTextProperty,
          'search[municipality]': vm.inputTextMunicipality,
          'search[district]': vm.inputTextDistrict,
          'search[ownerName]': vm.inputTextOwner,
          include: ['demands', 'owners']
        }, {})
        .$promise.then(function (response) {
          vm.realEstates = response.rows;
          vm.totalCount = response.count;
          initializeCheckboxList();
        })
        .catch(ErrorHandler('Misslyckades att hämta fastigheter.'))
        .finally(function () {
          vm.ready = true;
          vm.readyProgressCircular = true;
        });
    }
  }

  function getPropertyOwners(propertyId) {
    var property = vm.realEstates.filter(function (property) {
      return property.id === propertyId;
    })[0];

    if (!property.owners) {
      vm.readyOwners[property.id] = false;
      return RealEstate.get({
        id: propertyId
      }, {})
        .$promise.then(function (response) {
          property.owners = response.owners;
        })
        .catch(ErrorHandler('Misslyckades att hämta fastighetsägare.'))
        .finally(function () {
          vm.readyOwners[propertyId] = true;
        });
    }
  }

  function initializeCheckboxList() {
    vm.realEstates.forEach(function (property) {
      vm.selectedPropertyIds[property.id] = true;
    });
    vm.allPropertiesBoxChecked = true;
  }

  function editDemandAndMotivation() {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'md',
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation-call/new/modals/edit-motivation-modal.html',
      controller: 'EditMotivationModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        propertiesArr: function () {
          var properties = vm.realEstates.filter(function (property) {
            return vm.selectedPropertyIds[property.id] === true;
          });
          return properties;
        },
        negotiationType: function() {
          return vm.parentCtrl.negotiation.type;
        }
      }
    });
    modalInstance.result.then(function (demandAndMotivation) {
      vm.realEstates
        .filter(function (property) {
          return vm.selectedPropertyIds[property.id] === true;
        })
        .forEach(function (property) {
          angular.extend(property, {
            demand: demandAndMotivation
          });
          vm.selectedPropertyIds[property.id] = false;
          vm.allPropertiesBoxChecked = false;
        });
    });
  }

  vm.computeLastRecord = computeLastRecord;

  function computeLastRecord() {
    if (vm.totalCount - vm.firstRecordInCurrentPage < vm.pageLimit - 1) {
      return vm.totalCount;
    } else {
      return vm.firstRecordInCurrentPage + vm.pageLimit - 1;
    }
  }

  vm.toggleAllPropertiesSelection = toggleAllPropertiesSelection;

  function toggleAllPropertiesSelection() {
    vm.realEstates.forEach(function (property) {
      if (vm.selectedPropertyIds[property.id] !== null) {
        vm.selectedPropertyIds[property.id] = vm.allPropertiesBoxChecked;
      }
    });
  }

  vm.updateAllPropertiesBoxState = updateAllPropertiesBoxState;

  function updateAllPropertiesBoxState() {
    // auto select master checkbox if all other checkboxes are selected
    // deselect master checkbox if at least one checkbox is not selected
    vm.allPropertiesBoxChecked = Object.keys(vm.selectedPropertyIds)
      .map(function (propertyId) {
        return vm.selectedPropertyIds[propertyId] === true || vm.selectedPropertyIds[propertyId] === null;
      })
      .every(function (isSelected) {
        return isSelected;
      });
  }

  vm.atLeastOneSelectedPropertyId = atLeastOneSelectedPropertyId;

  function atLeastOneSelectedPropertyId() {
    return Object.keys(vm.selectedPropertyIds)
      .map(function (propertyId) {
        return vm.selectedPropertyIds[propertyId] === true;
      })
      .some(function (isSelected) {
        return isSelected;
      });
  }

  vm.saveProperties = saveProperties;

  function saveProperties() {
    vm.saving = true;

    return Negotiation.savePropertyDemands({
      id: $routeParams.id
    }, vm.realEstates)
      .$promise.then(
        function (response) {
          Flash.set('Yrkande och motiv har sparats', 'success');
          return vm.parentCtrl.negotiationQueryPromise;
        })
      .catch(ErrorHandler('Misslyckades att hämta fastigheter.'))
      .finally(function () {
        vm.parentCtrl.getNegotiation();
        vm.currentPage = 0;
        vm.firstRecordInCurrentPage = 1;
        vm.saving = false;
        vm.editMode = !vm.editMode;
      });
  }

  //Custom pagination controlls for this view.
  vm.goToPrevPage = goToPrevPage;
  vm.goToNextPage = goToNextPage;

  function goToPrevPage() {
    vm.currentPage = vm.currentPage - 1;
    vm.firstRecordInCurrentPage = vm.currentPage * vm.pageLimit + 1;
  }

  function goToNextPage() {
    vm.currentPage = vm.currentPage + 1;
    vm.firstRecordInCurrentPage = vm.currentPage * vm.pageLimit + 1;
  }
}
})();
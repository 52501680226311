(function () {
'use strict';

angular.module('hyresgastApp').filter('hyNegotiationStates', function(NegotiationStates) {
  return function(value) {
    return hyNegotiationStates(value, NegotiationStates);
  };
});

function hyNegotiationStates(value, NegotiationStates) {
  switch (value) {
    case NegotiationStates.NEW:
      return 'Ny';
    case NegotiationStates.IN_PREPARATION:
      return 'Bereds';
    case NegotiationStates.IN_PROGRESS:
      return 'Pågår';
    case NegotiationStates.SETTLEMENT:
      return 'Uppgörelse';
    case NegotiationStates.BLOCKED:
      return 'Strandad';
    case NegotiationStates.PARTIALLY_BLOCKED:
      return 'Delvis strandad';
    case NegotiationStates.FINISHED_OUTSIDE_PORTAL:
      return 'Avslutas utanför portalen';
    case NegotiationStates.FINISHED:
      return 'Avslutad';
    default:
      return 'Alla statusar';
  }
}

})();
(function () {
'use strict';

angular.module('hyresgastApp').directive('hyApartmentSelector2', apartmentSelector2);

function apartmentSelector2($window, $timeout, $filter,
  HyUtils,
  ApartmentSelector2) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/apartment-selector-2/apartment-selector-2.html',
    scope: {
      isModal: '=',
      allowEmptyProperties: '=',
      emptyOnly: '=',
      apartmentsArguments: '=',
      incomingProperties: '=',
      userId: '=',

      model: '=',
      emptyProperties: '=',
    },
    link: link,
  };
  return directive;

  // eslint-disable-next-line no-unused-vars
  function link(scope, element, attrs) {
    scope.model = [];
    scope.emptyProperties = [];
    scope.getNames = arr => arr ? arr.map(x=>x.name).slice(0, 2).join(', ') : null;
    scope.getHtmlTooltip = arr => arr ? arr.slice(1).join('<br/>') : null;
    //TODO: extract to fe-common
    scope.timed = f => $timeout(() => { f() }, 400);

    scope.offset = 0;
    scope.limit = 24;

    scope.getPropertiesVirtual = () => {
      scope.propertiesVirtual = scope.properties.slice(scope.offset, scope.offset + scope.limit);
    }

    scope.onlyMyProperties = true;
    scope.changePropertiesMethod = onlyMyProperties => {
      scope.timed(() => {
        scope.onlyMyProperties = !onlyMyProperties;
        scope.offset = 0;
        scope.getProperties();
        scope.getPropertiesVirtual();
      });
    };

    scope.getProperties = () => {
      scope.propertiesLoading = true;
      let params = {
        offset: 0,
        limit: scope.limit * 18,
        order: 'propertyDesignation',
        direction: 'ASC',
      };
      if (scope.onlyMyProperties && scope.userId) {
        params.userId = scope.userId;
      }
      if (scope.filters) {
        if (scope.filters.municipalities.length) {
          Object.assign(params, {'filters[municipality]': scope.filters.municipalities });
        }
        if (scope.filters.propertyDesignations.length) {
          Object.assign(params, {'filters[propertyDesignation]': scope.filters.propertyDesignations });
        }
        if (scope.filters.owners.length) {
          Object.assign(params, {'search[owners]': scope.filters.owners });
        }
        if (scope.filters.counterparts.length) {
          Object.assign(params, {'search[counterparts]': scope.filters.counterparts });
        }
      }
      ApartmentSelector2.getProperties(params)
        .$promise.then(response => {
          scope.properties = response.rows;
          scope.propertiesTotalCount = response.count;
          scope.propertiesTotalCountVirtual = scope.properties.length;
          for (let item of scope.properties) {
            item.selectionState = PROPERTY_CARD_STATE.NOT_SELECTED;
          }
          scope.tickedProperties = {};
          scope.allProperties = true;
          scope.toggleAllProperties();
          scope.propertiesLoading = false;
          scope.getPropertiesVirtual();
          scope.checkBasket();
        });
    };

    scope.getDictionaries = sendCurrent => {
      let params = sendCurrent ? {
        filters: scope.filters,
        filterSearches: scope.filterSearches,
      } : {};
      ApartmentSelector2.getDictionaries(params)
        .$promise.then(response => {
          delete response[scope.skipDictionary];
          Object.assign(scope.filterDictionaries, response);
          //scope.filterDictionaries = response;
        });
    };

    scope.properties = [];
    scope.filterSearches = {
      municipality: '',
      propertyDesignation: '',
      owner: '',
      counterpart: '',
    };
    scope.filterDictionaries = {
      municipalities: [],
      propertyDesignations: [],
      owners: [],
      counterparts: [],
    };
    function resetFilters() {
      scope.filters = {
        municipalities: [],
        propertyDesignations: [],
        owners: [],
        counterparts: [],
      };
    }
    resetFilters();
    scope.getDictionaries(false);
    scope.getProperties();
    scope.isSomethingSelected = () => {
      scope.filters.present =
       (scope.filters.propertyDesignations.length ||
        scope.filters.municipalities.length ||
        scope.filters.owners.length ||
        scope.filters.counterparts.length);
    };

    let filtersChanged = () => {
      scope.isSomethingSelected();
      scope.getProperties();
      scope.getDictionaries(true);
      scope.offset = 0;
    };
    scope.skipDictionary = null;
    let maybePush = (filterType, value) => {
      let arr = scope.filters[filterType];
      scope.skipDictionary = filterType;
      if (!arr.includes(value)) {
        arr.push(value);
        filtersChanged();
      }
    };
    let remove = (filterType, value) => {
      let arr = scope.filters[filterType];
      scope.skipDictionary = filterType;
      arr.splice(arr.findIndex(x => x === value), 1);
      filtersChanged();
    }

    scope.addMunicipalityFilter = item => maybePush('municipalities', item);
    scope.removeMunicipalityFilter = item => remove('municipalities', item);

    scope.addPropertyFilter = item => maybePush('propertyDesignations', item);
    scope.removePropertyFilter = item => remove('propertyDesignations', item);

    scope.addOwnersFilter = item => maybePush('owners', item);
    scope.removeOwnersFilter = item => remove('owners', item);

    scope.addCounterpartsFilter = item => maybePush('counterparts', item);
    scope.removeCounterpartsFilter = item => remove('counterparts', item);

    scope.removeAllFilters = () => {
      resetFilters();
      filtersChanged();
    };

    //setup scroll
    // eslint-disable-next-line angular/document-service, no-undef
    let stickyEl = document.getElementById('floatingFilters');
    let stickyTop = stickyEl.offsetTop;

    let onScrollAction = function () {
      // eslint-disable-next-line eqeqeq
      if (stickyTop < 300) {
        stickyTop = stickyEl.offsetTop;
      }
      // eslint-disable-next-line angular/window-service, no-undef
      let windowTop = window.scrollY;
      //console.log('scroll', windowTop, stickyTop);
      if (windowTop >= stickyTop) {
        stickyEl.classList.add('floatingReally');
      } else {
        stickyEl.classList.remove('floatingReally');
      }
    };

    angular.element($window).on('scroll', onScrollAction);
    scope.$on('$destroy', function () {
      angular.element($window).off('scroll', onScrollAction);
    });

    const PROPERTY_CARD_STATE = {
      NOT_SELECTED: 0,
      LOADING: 1,
      READY_NO_APARTMENTS: 2,
      READY_APARTMENTS: 3,
    };
    scope.PROPERTY_CARD_STATE = PROPERTY_CARD_STATE;

    scope.selectProperty = property => {
      if (scope.emptyOnly) {
        return;
      }
      if (scope.selectedProperty) {
        if (scope.selectedProperty === property) {
          return;
        }
        scope.selectedProperty.selectionState = PROPERTY_CARD_STATE.NOT_SELECTED;
      }
      for (let item of scope.properties) {
        item.selectionState = PROPERTY_CARD_STATE.NOT_SELECTED;
      }
      property.selectionState = PROPERTY_CARD_STATE.LOADING;
      scope.selectedProperty = property;
      scope.getApartments();
    };

    /**
     * gets all ticked ids
     * @param {Object} ticked
     * @returns {Array<number>}
     */
    let getTicked = ticked => Object.keys(ticked).filter(k => ticked[k]).map(id => parseInt(id));
    /**
   * determines if there is something ticked
   * @param {Object} ticked
   * @returns {boolean} true if something is ticked
   */
    let hasTicked = ticked => !!getTicked(ticked).length;

    scope.hasTickedApartments = () => hasTicked(scope.tickedApartments);
    scope.hasTickedProperties = () => hasTicked(scope.tickedProperties);

    scope.apartmentsFilter = el => {
      if (!scope.apartmentFilter || scope.apartmentFilter.length === 0) {
        return true;
      }
      let phrase = scope.apartmentFilter.toLowerCase();
      return (el.streetAddress+el.nationalNo).toLowerCase().includes(phrase);
    };

    scope.vm = { apartmentFilterPhrase: '', basketFilterPhrase: '', selectedAgreementCodes: [] };
    scope.basketFilter = el => {
      let gotText = scope.vm.basketFilterPhrase.length > 0;
      let gotType = scope.vm.selectedAgreementCodes.length > 0;
      if (!gotText && !gotType) {
        return true;
      }

      let { text, type } = el.property ?
        {
          text: el.streetAddress+el.nationalNo,
          type: el.property.agreementCode
        } : {
          text: el.propertyDesignation+el.municipality,
          type: el.agreementCode
        };

      if (gotText && !text.toLowerCase().includes(scope.vm.basketFilterPhrase.toLowerCase())) {
        return false;
      }
      if (gotType && !scope.vm.selectedAgreementCodes.includes(type)) {
        return false;
      }

      return true;
    };
    scope.apartmentsFilter = el => {
      return !scope.vm.apartmentFilterPhrase.length ||
              el.streetAddress.includes(scope.vm.apartmentFilterPhrase) ||
              el.nationalNo.includes(scope.vm.apartmentFilterPhrase);
    };

    scope.getApartments = () => {
      let propertyId = scope.selectedProperty.id;
      ApartmentSelector2.getApartments({ propertyId })
        .$promise.then(response => {
          scope.apartments = response;
          scope.selectedProperty.selectionState = scope.apartments.length > 0
            ? PROPERTY_CARD_STATE.READY_APARTMENTS
            : PROPERTY_CARD_STATE.READY_NO_APARTMENTS;

          scope.allApartments = true;
          scope.tickedApartments = {};
          scope.toggleAllApartments();

          /*
          $timeout(() => {
            // eslint-disable-next-line angular/document-service, no-undef
            let el = document.getElementById(`property${propertyId}`);
            el.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
          }, 500);
          */
        });
    };

    let reductor = (acc, el) => { acc[el.id] = true; return acc; };
    scope.toggleAllProperties = () => {
      if (getTicked(scope.tickedProperties).length < scope.properties.length) {
        scope.tickedProperties = scope.properties.reduce(reductor, {});
      } else {
        scope.tickedProperties = {};
      }
      scope.checkBasket();
    };
    scope.toggleAllApartments = () => {
      if (getTicked(scope.tickedApartments).length < scope.apartments.length) {
        scope.tickedApartments = scope.apartments.reduce(reductor, {});
      } else {
        scope.tickedApartments = {};
      }
    };


    let fixEmptyProperties = () => {
      if (scope.emptyProperties) {
        scope.emptyProperties = scope.emptyProperties.filter(p => !scope.model.find(a => a.property.id === p.id));
      }
    };

    /**
     * add apartments avoiding duplicates
     * @param {Array} newApartments
     */
    let addUniqueApartments = newApartments => {
      scope.model = HyUtils.unionArraysFn(scope.model, newApartments, (a, b) => {
        return a.streetAddress+a.nationalNo === b.streetAddress+b.nationalNo;
      });
    };
    scope.addSelected = () => {
      let tickedApartments = getTicked(scope.tickedApartments);
      let newApartments = scope.apartments.filter(el => tickedApartments.includes(el.id));
      newApartments = newApartments.filter(el => scope.apartmentsFilter(el));
      newApartments.forEach(el => el.property = scope.selectedProperty);
      addUniqueApartments(newApartments);
      scope.tickedApartments = {};
      scope.tickedProperties[scope.selectedProperty.id] = false;
      scope.allApartments = false;
      if (!scope.hasTickedProperties()) {
        scope.allProperties = false;
      }
      sortModel();
      scope.checkBasket();
      fixEmptyProperties();
    };
    scope.addEmptyProperty = () => {
      let selectedProperties = getTicked(scope.tickedProperties)
        .filter(id => !scope.emptyProperties.find(e => e.id === id))
        .map(id => scope.properties.find(p => p.id === id));
      if (!selectedProperties.length &&
           scope.selectedProperty && !scope.emptyProperties.find(x => x.id === scope.selectProperty.id)) {
        selectedProperties.push(scope.selectedProperty);
      }
      scope.emptyProperties = scope.emptyProperties.concat(selectedProperties);
      scope.tickedProperties = {};
      scope.allProperties = false;
      sortEmptyProperties();
      scope.checkBasket();
    };
    scope.addEveryApartment = () => {
      var ticked = getTicked(scope.tickedProperties);
      if (!ticked.length) {
        return;
      }
      var propertiesDone = 0;
      var apartmentCollector = [];
      scope.busyAddEvery = true;
      scope.properties.forEach(p => {
        if (!ticked.includes(p.id)) return;
        ApartmentSelector2.getApartments({ propertyId: p.id })
          .$promise.then(response => {
            response.forEach(el => el.property = p);
            apartmentCollector.push(...response);
            propertiesDone++;

            if (propertiesDone === ticked.length) {
              addUniqueApartments(apartmentCollector);
              scope.busyAddEvery = false;
              scope.allProperties = false;
              scope.tickedProperties = {};
              sortModel();
              scope.checkBasket();
            }
          });
      });
    };

    scope.doSearch = (filter, dictionaryType, dictionary) => {
      scope.filtersLoading = true;
      ApartmentSelector2.getDictionary({
        dictionaryType,
        filter,
        filters: scope.filters,
      }).$promise.then(response => {
        dictionary.length = 0;
        dictionary.push.apply(dictionary, response);
        scope.filtersLoading = false;
      });
    };
    scope.doSearchMunicipality = filter => scope.doSearch(filter, 'municipality', scope.filterDictionaries.municipalities);
    scope.doSearchPropertyDesignation = filter => scope.doSearch(filter, 'propertyDesignation', scope.filterDictionaries.propertyDesignations);
    scope.doSearchOwner = filter => scope.doSearch(filter, 'owner', scope.filterDictionaries.owners);
    scope.doSearchCounterpart = filter => scope.doSearch(filter, 'counterpart', scope.filterDictionaries.counterparts);

    let agreementCode = property =>
      $filter('hyNegotiationAgreementTypes')(property.negotiationAgreementCode, property.hasNegotiationAgreement);
    let setAgreementCodes = () => {
      scope.emptyProperties.forEach(x => x.agreementCode = agreementCode(x));
      scope.model.forEach(x => x.property.agreementCode = agreementCode(x.property));
      let x1 = scope.emptyProperties.map(x => x.agreementCode);
      let x2 = scope.model.map(x => agreementCode(x.property));
      // eslint-disable-next-line no-undef
      scope.agreementCodes = Array.from(new Set(x1.concat(x2)));
    }
    scope.checkBasket = () => {
      scope.hasSelectedProperties = (scope.model && !!scope.model.length) || (scope.emptyProperties && !!scope.emptyProperties.length);
      scope.basketVisible = scope.hasSelectedProperties || scope.hasTickedProperties();
      if (!scope.basketVisible || !scope.hasSelectedProperties) {
        scope.basketOpened = false;
      }
      setAgreementCodes();
    };
    scope.toggleBasketOpened = () => {
      if (scope.hasSelectedProperties) {
        scope.basketOpened = !scope.basketOpened;
      }
      if (scope.basketOpened) {
        // eslint-disable-next-line angular/document-service, no-undef
        let el = document.getElementById('basketFilter');
        el.focus();
      }
    };

    let separateByProperty = () => {
      let previous = scope.model[0];
      scope.model.forEach((row, index) => {
        if (index > 0) {
          previous = scope.model[index - 1];
          row.differentProperty = row.property.id !== previous.property.id;
        } else {
          row.differentProperty = true;
        }
      });
    };
    let sortModel = () => {
      let hash = a => `${a.property.district}${a.property.propertyDesignation}${a.streetAddress}${a.nationalNo}`;
      scope.model.sort((a, b) => hash(a) > hash(b) ? 1 : -1);
      separateByProperty();
    };
    let sortEmptyProperties = () => {
      scope.emptyProperties.sort((a, b) => a.id > b.id ? 1: -1);
    };


    scope.deleteSelected = id => {
      scope.model = scope.model.filter(row => row.id !== id);
      sortModel();
    };
    scope.deleteProperty = id => {
      scope.model = scope.model.filter(a => a.property.id === id);
      sortEmptyProperties();
      sortModel();
      scope.checkBasket();
    }
    scope.deleteEmptyProperty = id => {
      scope.emptyProperties = scope.emptyProperties.filter(p => p.id !== id);
      sortEmptyProperties();
      scope.checkBasket();
    };
    scope.deleteEverything = () => {
      if ((scope.vm.basketFilterPhrase && scope.vm.basketFilterPhrase.length > 0) ||
          (scope.vm.selectedAgreementCodes && scope.vm.selectedAgreementCodes.length)) {
        scope.emptyProperties = scope.emptyProperties.filter(x => !scope.basketFilter(x));
        scope.model = scope.model.filter(x => !scope.basketFilter(x));
        scope.vm.basketFilterPhrase = '';
        scope.vm.selectedAgreementCodes = [];
      } else {
        scope.emptyProperties = [];
        scope.model = [];
      }
      scope.checkBasket();
    };
  }
}
})();
(function () {
'use strict';


/**
 * @typedef {Object} ComplexPhone
 * @property {string} countryCode
 * @property {string} number
 */

/**
 * @typedef {Object} VisualOptions
 * @property {boolean} isGreen
 * @property {string} size
 */

angular
  .module('hyUtils', [])
  .factory('HyUtils', HyUtils);

function HyUtils(NegotiationRoles, $window, $uibModal) {

  /**
   * determines if element is visible on the screen
   * @param {HTMLElement} el
   * @returns {boolean}
   */
  let isVisible = el => el.getClientRects().length > 0;

  /**
   * unions two arrays of primitive elements
   * @param {Array} a
   * @param {Array} b
   * @returns {Array}
   */
  let unionArrays = (a, b) => a.concat(b.filter((item) => a.indexOf(item) < 0));

  /**
   * unions two arrays of objects using compare function
   * @param {Array} a
   * @param {Array} b
   * @param {function(Object, Object):boolean} fn
   * @returns {Array}
   */
  let unionArraysFn = (a, b, fn) => a.concat(b.filter(item => !a.some(elA => fn(item, elA))));

  /**
   * shows default modal
   * @param {string} ctrl
   * @param {string} template
   * @param {object} variables object with functions that resolves modal ctrl variables
   * @param {*} [result] result callback
   * @param {*} [cancel] cancel callback
   * @param {*} [error] error callback
   * @param {VisualOptions} visualOptions visual options
   */
  let showDefaultModal = (ctrl, template, variables, result, cancel, error, visualOptions) => {
    if (!visualOptions) {
      visualOptions = {};
    }
    visualOptions.size = visualOptions.size ? visualOptions.size : 'lg';

    let resolve = {};
    Object.keys(variables).forEach(k => resolve[k] = () => variables[k]);
    let options = {
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: visualOptions.size,
      templateUrl: template,
      controller: ctrl,
      controllerAs: 'vm',
      windowClass: visualOptions.isGreen ? 'modal-content--green' : 'modal-content--secondary',
      resolve,
    };
    if (ctrl.toLowerCase().includes('modal')) {
      Object.assign(options, {
        backdrop: 'static',
        keyboard: false,
      });
    }
    var instance = $uibModal.open(options);

    if (result) {
      instance.result.then(res => result(res));
    }
    if (cancel) {
      instance.closed.then(() => cancel());
    }
    if (error) {
      instance.result.catch(err => error(err));
    }
  }

  /**
   * shows modal with question and answer buttons. If no answers provided, shows Yes/No
   * @param {string} question
   * @param {Array.<string>} [answers]
   * @param {number} [type] visual appearence type, 1 or 2, 1 is default
   */
  let askQuestion = (question, answers, type) => {
    if (!answers) {
      answers = ['Ja', 'Nej'];
    }
    if (!type) {
      type = 1;
    }

    let windowClass = 'modal-content--secondary';
    if (type === 2) {
      windowClass += ' modal--centred modal-content--green';
    }
    let templateUrl = `components/modal/question/question-${type}.html`;
    return new Promise((resolve, reject) => {
      var instance = $uibModal.open({
        animation: true,
        ariaLabelledBy: 'modal-title-top',
        ariaDescribedBy: 'modal-body-top',
        size: 'md',
        templateUrl,
        controller: 'QuestionCtrl',
        controllerAs: 'vm',
        windowClass,
        resolve: {
          message: () => question,
          answers: () => answers,
        }
      });

      instance.result.then(res => resolve(res));
      instance.result.catch(err => reject(err));
    });
  }

  let spitOutAttachment = (data, filename, contentType) => {
    const supablob = new Blob([new Uint8Array(data)], {
      type: contentType,
    });

    var downloadLink = angular.element('<a></a>');
    downloadLink.attr('href', $window.URL.createObjectURL(supablob));
    downloadLink.attr('download', filename);
    downloadLink[0].click();
  }

  /**
   * Finds role by code.
   * Chief and deChief as needed.
   * No, lookup is not better, deal with it
   *
   * @param {int|string} code
   * @param {boolean|undefined} wantChief
   */
  let findNegotiationRole = (code, wantChief) => {
    // eslint-disable-next-line no-undefined
    if (wantChief === undefined) {
      // eslint-disable-next-line eqeqeq
      return Object.values(NegotiationRoles).find(nr => nr.code == code);
    } else {
      // eslint-disable-next-line eqeqeq
      var key = Object.keys(NegotiationRoles).find(nrk => NegotiationRoles[nrk].code == code);
      var hasChief = (key.indexOf('_CHIEF') > 0);
      if (hasChief === wantChief) {
        return NegotiationRoles[key];
      } else if ((!hasChief) && (wantChief)) {
        return NegotiationRoles[key + '_CHIEF'];
      } else if ((hasChief) && (!wantChief)) {
        return NegotiationRoles[key.substring(0, key.indexOf('_CHIEF'))];
      }
    }
  }

  /**
   * @param {string} phoneString
   * @returns {ComplexPhone} countryCode and number
   */
  let tamePhoneNumber = phoneString => {
    const validCountryCodes = ['+358', '+47', '+45', '+44', '+371'];

    phoneString = phoneString
      .replace(/\s+/g, '')
      .replace(/-+/g, '');

    if (phoneString.startsWith('0')) {
      return { countryCode: '+46', number: phoneString.substring(1) };
    } else if (phoneString.startsWith('+46')) {
      return { countryCode: '+46', number: phoneString.substring(3) };
    } else if (phoneString.startsWith('+')) {
      let countryCode = validCountryCodes.find(code => phoneString.startsWith(code));
      return { countryCode, number: phoneString.substring(countryCode.length) };
    } else {
      return { countryCode: '+46', number: phoneString };
    }
  };

  let parseDate = str => {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{4,}Z?$/;
    let hasHiPrecisionTime = regex.test(str);
    return hasHiPrecisionTime 
      ? new Date(str)
      : new Date.fromString(str);
  };

  /**
   * returns Date object if value is string and contains one
   * @param {string} key
   * @param {any} value
   * @returns {any | Date}
   */
  let dtRev = (key, value) => {
    if (typeof value === 'string') {
      let d = parseDate(value);
      return isNaN(d) ? value : d;
    }
    return value;
  };

  /**
   * searches for object in all controller instances
   * @param {string} objectName 
   */
  let bruteSearch = (scope, objectName) => {
    let child = null;
    for(var cs = scope.$$childHead; cs; cs = cs.$$nextSibling) {
      if (cs.$$childTail && cs.$$childTail[objectName]) {
        child = cs.$$childTail[objectName];
      }
    }
    return child;
  };

  /**
   * gets difference in days between two given dates
   * date strings should be ISO or in current locale, otherwise it will fail
   * @param {string|Date} date1
   * @param {string|Date} date2
   */
  let diffDays = (date1, date2) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    date1 = new Date(date1.toDateString());
    date2 = new Date(date2.toDateString());
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  /**
   * determines if luhn is valid
   * @param {string} orgNumber 
   * @returns {boolean}
   */
  let validateLuhn = orgNumber => {
    var trimmed = String(orgNumber).replace(/[\s]/g, "")
      , length = trimmed.length
      , odd = false
      , total = 0
      , calc
      , calc2;

    if (length === 0) {
      return true;
    }

    if (!/^[0-9]+$/.test(trimmed)) {
      return false;
    }

    for (var i = length; i > 0; i--) {
      calc = parseInt(trimmed.charAt(i - 1));
      if (!odd) {
        total += calc;
      } else {
        calc2 = calc * 2;

        switch (calc2) {
          case 10: calc2 = 1; break;
          case 12: calc2 = 3; break;
          case 14: calc2 = 5; break;
          case 16: calc2 = 7; break;
          case 18: calc2 = 9; break;
          default: calc2 = calc2;
        }
        total += calc2;
      }
      odd = !odd;
    }

    return (total !== 0 && (total % 10) === 0);
  };

  /**
   * gets streetAddress from object
   * @param {Object} address 
   * @returns {string}
   */
  const renderStreetAddress = address => [
    address.street,
    address.streetNumber,
    address.entrance
  ].filter(x => x).join(' ');

  /**
   * parses street address
   * @param {string} streetAddress
   * @returns {{address: string, number: number, entrance: string}}
   */
  const splitStreetAddress = streetAddress => {
    let street = null;
    let streetNumber = null;
    // eslint-disable-next-line no-undefined
    let entrance = undefined;
    let tokens = streetAddress.split(' ');

    if (tokens.length > 2) {
      let last = tokens.pop();
      if (!isNaN(parseInt(last))) {
        streetNumber = parseInt(last);
      } else {
        entrance = last;
        streetNumber = parseInt(tokens.pop());
      }
      street = tokens.join(' ');
    } else {
      [street, streetNumber] = tokens;
    }
    if (entrance) {
      entrance = entrance.toUpperCase();
    }
    return { street, streetNumber, entrance };
  };

  let whereAmI = () => {
    const hostMaps = {
      localhost: {
        FP: 'localhost:3000',
        SP: 'localhost:3344',
      },
      test: {
        FP: 'forhandlarportalen-test.azurewebsites.net',
        SP: 'fpstats-test.azurewebsites.net',
      },
      demo: {
        FP: 'forhandlarportalen-demo.azurewebsites.net',
        SP: 'fpstats-demo.azurewebsites.net',
      },
      prod: {
        FP: 'forhandlarportalen.se',
        SP: 'fpstats-prod.azurewebsites.net',
      }
    };
    let myhost = $window.location.hostname;
    let myport = $window.location.port;
    let key = Object.keys(hostMaps).find(k => 
      (hostMaps[k].FP.includes(myhost)) || 
      (hostMaps[k].SP.includes(myhost)));
    let host = hostMaps[key];
    let isFP = host.FP.includes(myhost) && (host.FP.includes(':') ? host.FP.includes(myport) : true);
    let isSP = host.SP.includes(myhost) && (host.SP.includes(':') ? host.SP.includes(myport) : true);
    return key ? { [key]: true, host, isFP, isSP } : {};
  }

  return {
    tamePhoneNumber,
    findNegotiationRole,
    spitOutAttachment,
    askQuestion,
    showDefaultModal,
    unionArrays,
    dtRev,
    unionArraysFn,
    isVisible,
    bruteSearch,
    diffDays,
    validateLuhn,
    renderStreetAddress,
    splitStreetAddress,
    whereAmI,
    parseDate,
  };
}
})();
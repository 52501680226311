(function () {
'use strict';

angular.module('hyresgastApp').controller('EditUserCtrl', EditUserCtrl);

function EditUserCtrl($location, $routeParams, Auth, Flash, User, ErrorHandler) {
  // Responsible for create, update and delete

  var vm = this;
  vm.user = {};
  vm.save = save;
  vm.setPassword = setPassword;

  getUser();

  ////////////

  function getUser() {
    return User.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.ready = true;
        vm.user = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta användaren.'));
  }

  function save() {
    vm.saving = true;
    User.save(vm.user)
      .$promise.then(
        function(response) {
          Flash.set('Ändring av användare lyckades.', 'success');
          $location.path('/users/' + response.id);
        })
      .catch(ErrorHandler('Användare med denna e-postadress är redan registrerad.'))
      .finally(function() {
        vm.saving = false;
      });
  }

  function setPassword() {
    vm.savingPassword = true;
    vm.credentials['id'] = Auth.currentUser().id;

    User.setPassword(vm.credentials)
      .$promise.then(
        function(response) {
          Flash.set('Ändring av lösenord lyckades.', 'success');
        })
      .catch(ErrorHandler('Ändring av lösenord misslyckades. Kontrollera ditt nuvarande lösenord.'))
      .finally(function() {
        vm.credentials = {};
        vm.verifyPassword = '';
        vm.passwordForm.$setPristine();
        vm.passwordForm.$setUntouched();
        vm.savingPassword = false;
      });
  }
}

})();
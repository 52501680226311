(function () {
'use strict';

angular.module('reportCellValue', []).directive('reportCellValue', [reportCellValue]);

function reportCellValue() {
  var directive = {
    scope: {
      value: '=',
      type: '@',
    },
    restrict: 'E',
    templateUrl: 'resources/reports/report-cell-value.html',
  };

  return directive;
}

})();
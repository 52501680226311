(function () {
'use strict';

//TODO: move to fe-common

angular.module('hyresgastApp').controller('DeletionConfirmationModalCtrl', DeletionConfirmationModalCtrl);

function DeletionConfirmationModalCtrl($uibModalInstance, message) {
  var vm = this;

  vm.message = message;

  vm.confirm = function() {
    $uibModalInstance.close();
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

})();
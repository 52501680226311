(function () {
'use strict';

angular.module('hyresgastApp').factory('FreeAgreement', function($resource) {
  return $resource(
    '/api/agreements/:agreementId',
    {
      agreementId: '@agreementId',
      id: '@id',
      userId: '@userId',
    },
    {
      list: {
        url: '/api/agreements/list/:userId',
        method: 'GET',
      },
      create: {
        url: '/api/agreements/create',
        method: 'POST',
      },
      finishCreate: {
        url: '/api/agreements/finish-create',
        method: 'POST',
      },
      changeParticipants: {
        url: '/api/agreements/change-participants',
        method: 'POST',
      },
      queryDocument: {
        url: '/api/agreements/:agreementId/documents',
        method: 'GET',
        isArray: true
      },
      queryProperty: {
        url: '/api/agreements/:agreementId/real-estate',
        method: 'GET',
      },
      deleteProperties: {
        url: '/api/agreements/:agreementId/real-estate/',
        method: 'DELETE'
      },
      mergeProperties: {
        url: '/api/agreements/:agreementId/real-estate',
        method: 'POST',
      },
      unlockPending: {
        url: '/api/agreements/:agreementId/unlock-pending',
        method: 'POST',
      },
      deletePending: {
        url: '/api/agreements/:agreementId/delete-pending',
        method: 'POST',
      },
      signAgreement: {
        url: '/api/agreements/:agreementId/sign-agreement',
        method: 'POST',
      },
      deleteAgreement: {
        url: '/api/agreements/:agreementId',
        method: 'DELETE'
      },
      dropAgreement: {
        url: '/api/agreements/:agreementId/drop-agreement',
        method: 'GET',
      },
      pullOut: {
        url: '/api/agreements/:agreementId/pull-out',
        method: 'GET',
      },
      downloadDocument: {
        url: '/api/agreements/:agreementId/documents/:id',
        method: 'GET',
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return {
            data: data,
            headers: headersGetter
          };
        }
      },
      deleteDocument: {
        url: '/api/agreements/:agreementId/documents/:id',
        method: 'DELETE'
      },
      getScriveStatus: {
        url: '/api/agreements/:agreementId/scrive-status',
        method: 'GET'
      },
      getSignedAgreement: {
        url: '/api/agreements/:agreementId/export-agreement-pdf',
        method: 'GET',
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return {
            data: data,
            headers: headersGetter
          };
        }
      },
      getPreview: {
        url: '/api/agreements/:agreementId/sign-agreement',
        method: 'POST',
        responseType: 'arraybuffer',
        transformResponse: function(data, headersGetter) {
          return {
            data: data,
            headers: headersGetter
          };
        }
      },
      getMyPendingCount: {
        url: '/api/agreements/get-my-pending-count',
        method: 'GET',
      },
      checkDelegation: {
        url: '/api/agreements/:id/delegation/check',
        method: 'POST'
      },
      inviteDelegation: {
        url: '/api/agreements/:id/delegation/invite',
        method: 'POST'
      },
      deleteDelegation: {
        url: '/api/agreements/:id/delegation/remove',
        method: 'POST'
      },
      addVoidUser: {
        url: '/api/agreements/:id/void-user',
        method: 'POST'
      },
      deleteVoidUser: {
        url: '/api/agreements/:id/void-user',
        method: 'DELETE'
      }
    }
  );
});

})();
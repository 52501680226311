(function () {
'use strict';

angular
  .module('hyresgastApp')
  .controller('AddPropertiesCtrl', AddPropertiesCtrl);

function AddPropertiesCtrl($scope, $routeParams, $location,
  Auth, Negotiation, PropertyNegotiationTypes, ErrorHandler) {
  var vm = this;

  vm.busy = false;
  vm.Auth = Auth;
  vm.selectedApartments = [];
  vm.emptyProperties = [];
  
  let negotiationTypes = Object.values(PropertyNegotiationTypes);
  let negotiationType = $scope.$parent.vm.negotiation.type
  vm.isPropertyBased = negotiationTypes.includes(negotiationType);

  vm.save = () => {
    // eslint-disable-next-line no-undef
    let newPropertyIds = Array.from(new Set(vm.emptyProperties.map(x => x.id)
      .concat(vm.selectedApartments.map(x => x.property.id))));
    vm.busy = true;
    Negotiation.addProperties({
      id: $routeParams.id,
      newPropertyIds,
    // eslint-disable-next-line no-unused-vars
    }).$promise.then(response => {
      vm.busy = false;
      $location.path('/negotiations/' + $routeParams.id).search('view', 'overview');
    }).catch(() => {
      vm.busy = false;
      new ErrorHandler('Misslyckades att spara förhandling.');
    });
  };
}
})();
(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowRealEstateApartmentsCtrl', ShowRealEstateApartmentsCtrl);

function ShowRealEstateApartmentsCtrl($scope, $location, $routeParams, $timeout,
  RealEstate,
  DomainModals, ErrorHandler,
  ApartmentStatusTypes) {
  var vm = this;

  vm.pageLimit = 20;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'nationalNo'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.nh = {
    items: [
      { id: 'nh91', text: '-91' },
      { id: 'nh78', text: '-78' },
      { id: 'nh12', text: '-12' },
    ],
  };
  vm.nh.current = vm.nh.items[0];
  vm.listColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Normhyra', column: 'nh' },
    { title: 'Aktuell hyra', column: 'rent.monthlyRent' },
    { title: 'Hyra gäller fr.o.m', column: 'rent.effectiveFrom' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Yta (kvm)', column: 'livingSpace' },
    { title: 'Antal rum', column: 'rooms' },
    { title: 'Notering', column: 'rentFactors' }
  ];

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getPropertyApartments();
    }
  });
  vm.nhTableChange = () => {
    $timeout(() => {
      vm.pageOffset = 0;
      getPropertyApartments();
    }, 200);
  };

  let allTypes = {code:0, name:'', longName:'Alla'};
  vm.selectedStatusType = allTypes;
  vm.apartmentStatusTypes = [allTypes]
    .concat(Object.values(ApartmentStatusTypes));

  vm.statusTooltip = 'Övrig information:<br/>' +
    Object.values(ApartmentStatusTypes).map(t => `${t.name}-${t.longName}`).join('<br/>');

  vm.filterByStatusType = (row) => vm.selectedStatusType.code===0 ||
    row.statusString.includes(vm.selectedStatusType.name);

  vm.getPropertyApartments = getPropertyApartments;
  vm.getMonthlyRent = getMonthlyRent;
  vm.showApartmentHistory = showApartmentHistory;

  vm.property = null;
  $scope.$parent.$watch('vm.property', property => {
    if (property) {
      vm.property = property;
      vm.paginatorGetPropertyApartments();
    }
  });

  vm.setSlice = () => {
    vm.apartmentsSlice = vm.apartments.slice(vm.pageOffset, vm.pageOffset + vm.pageLimit);
  };
  vm.paginatorGetPropertyApartments = () => {
    if (!vm.ready) {
      getPropertyApartments();
    } else vm.setSlice();
  };
  function getPropertyApartments(filterMode) {
    if (filterMode) {
      vm.pageOffset = 0;
    }
    vm.ready = false;
    vm.id = $routeParams.id;
    RealEstate.queryApartmentsInProperty({
      id: vm.id,
      order: (vm.sortColumn === 'nh') ? ('rent.'+vm.nh.current.id) : vm.sortColumn,
      direction: vm.sortDirection,
      'search[nationalNo]': vm.inputNationalNo,
      'search[ownerNo]': vm.inputOwnerNo,
      newPropertyIds: vm.property.newPropertyIds,
    }).$promise.then(response => {
      vm.apartments = response.rows;
      vm.apartments.forEach(apartment => {
        apartment.statusString = Object.values(ApartmentStatusTypes)
          .filter(s => apartment.status & s.code)
          .map(s => s.colouredName || s.name)
          .join(', ');
      });
      vm.setSlice();
      vm.apartmentsCount = response.count;
    }).catch(ErrorHandler('Misslyckades att hämta lägenheter.'))
      .finally(() => { vm.ready = true });
  }

  function showApartmentHistory(apartment) {
    DomainModals.apartmentHistory(apartment);
  }

  function getMonthlyRent(apartment) {
    if (apartment.rent.monthlyRent) {
      return apartment.rent.monthlyRent;
    } else {
      return apartment.rent.yearlyRent / 12;
    }
  }
}

})();
(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationCallPropertiesCtrl', ShowNegotiationCallPropertiesCtrl);

function ShowNegotiationCallPropertiesCtrl($scope, $location, $routeParams, NegotiationCall, RealEstate, Auth, ErrorHandler) {
  var vm = this;
  vm.readyOwners = {};
  vm.parentCtrl = $scope.$parent.vm;
  vm.toggleOwnersList = {};
  vm.propertiesPageLimit = 30;
  vm.propertiesPageOffset = 0; // always show first items
  vm.queryProperties = queryProperties;
  vm.parentCtrl.negotiationCallPromise.then(function() {
    queryProperties();
    if (vm.parentCtrl.isPropertyBased) {
      vm.getPropertyOwners = getPropertyOwners;
      vm.sortColumn = $location.search().order || 'propertyDesignation'; // default
      vm.sortDirection = $location.search().direction || 'ASC'; // default
      vm.listColumns = [
        { title: 'Fastighetsbeteckning', column: 'propertyDesignation' },
        { title: 'Kommun', column: 'municipality' },
        { title: 'LM distrikt', column: 'district' },
        { title: 'Antal lägenheter', column: 'numNegotiatedApartments' },
        { title: 'Fastighetsägare', column: 'primaryOwner.name' }
      ];
    } else {
      vm.sortColumn = $location.search().order || 'propertyDesignation'; // default
      vm.sortDirection = $location.search().direction || 'ASC'; // default
      vm.listColumns = [
        { title: 'Fastighetsbeteckning', column: 'propertyDesignation' },
        { title: 'Gatuadress', column: 'streetAddress' },
        { title: 'Nationella lägenhetsnr', column: 'nationalNo' },
        { title: 'Lägenhetsnr', column: 'ownerNo' },
        { title: 'Kommun', column: 'municipality' },
        { title: 'Fastighetsägare', column: 'primaryOwner.name' }
      ];
    }
  });
  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.propertiesPageOffset = 0;
      queryProperties();
    }
  });

  /////////

  function queryProperties(filterMode) {
    if (filterMode) {
      vm.propertiesPageOffset = 0;
    }
    if (vm.parentCtrl.isPropertyBased || vm.parentCtrl.negotiationCall.isNewlyConstructedApartment) {
      vm.readyProperties = false;
      return NegotiationCall.queryProperty(
        {
          id: $routeParams.id,
          limit: vm.propertiesPageLimit,
          offset: vm.propertiesPageOffset,
          order: vm.sortColumn,
          direction: vm.sortDirection,
          'search[propertyDesignation]': vm.inputTextProperty,
          'search[municipality]': vm.inputTextMunicipality,
          'search[district]': vm.inputTextDistrict,
          'search[ownerName]': vm.inputTextOwner,
          include: ['owners', 'demands']
        },
        {}
      )
        .$promise.then(function(response) {
          vm.properties = response.rows;
          vm.propertyTotalCount = response.count;
          vm.rentFile = response.rentFile;
        })
        .catch(ErrorHandler('Misslyckades att hämta fastighet.'))
        .finally(function() {
          vm.readyProperties = true;
        });
    } else {
      vm.readyApartments = false;
      return NegotiationCall.queryApartment(
        {
          id: $routeParams.id,
          limit: vm.propertiesPageLimit,
          offset: vm.propertiesPageOffset,
          order: vm.sortColumn,
          direction: vm.sortDirection,
          'search[propertyDesignation]': vm.inputTextProperty,
          'search[streetAddress]': vm.inputTextStreetAddress,
          'search[nationalNo]': vm.inputTextNationalNumber,
          'search[ownerNo]': vm.inputTextApartmentNumber,
          'search[municipality]': vm.inputTextMunicipality,
          'search[ownerName]': vm.inputTextOwner,
          include: ['owners', 'demands']
        },
        {}
      )
        .$promise.then(function(response) {
          vm.apartments = response.rows;
          vm.apartmentsTotalCount = response.count;
          vm.rentFile = response.rentFile;
        })
        .catch(ErrorHandler('Misslyckades att hämta lägenhet.'))
        .finally(function() {
          vm.readyApartments = true;
        });
    }
  }

  function getPropertyOwners(propertyId) {
    var property = vm.properties.filter(function(property) {
      return property.id === propertyId;
    })[0];
    if (!property.owners) {
      vm.readyOwners[property.id] = false;
      return RealEstate.get(
        {
          id: propertyId
        },
        {}
      )
        .$promise.then(function(response) {
          property.owners = response.owners;
        })
        .catch(ErrorHandler('Misslyckades att hämta fastighetsägare.'))
        .finally(function() {
          vm.readyOwners[propertyId] = true;
        });
    }
  }
}

})();
(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationPropertyCtrl', ShowNegotiationPropertyCtrl);

function ShowNegotiationPropertyCtrl($scope, $location, $routeParams, $filter,
  Negotiation, RealEstate,
  DomainModals, ErrorHandler) {
  var vm = this;
  vm.pageLimit = 30;
  vm.pageOffset = 0;
  vm.sortColumn = $location.search().order || 'nationalNo'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.listColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Årshyra', column: 'currentYearlyRent' },
    { title: 'Månadshyra', column: 'currentMonthlyRent' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Yta (kvm)', column: 'livingSpace' },
    { title: 'Antal rum', column: 'rooms' },
    { title: 'Kökstyp', column: 'kitchenType' },
    { title: 'Notering', column: 'rentFactors' }
  ];

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getPropertyApartments();
    }
  });

  vm.getPropertyApartments = getPropertyApartments;
  vm.showApartmentHistory = showApartmentHistory;

  getProperty();

  ////////////

  function getProperty() {
    return RealEstate.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.property = response;
        getPropertyApartments();
      })
      .catch(ErrorHandler('Misslyckades att hämta fastigheten.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function showApartmentHistory(apartment) {
    DomainModals.apartmentHistory(apartment);
  }

  function getPropertyApartments(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }
    Negotiation.queryApartmentsInProperty({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id,
      limit: vm.pageLimit,
      offset: vm.pageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      newPropertyIds: vm.property.newPropertyIds,
      'search[nationalNo]': vm.inputNationalNo,
      'search[ownerNo]': vm.inputOwnerNo
    })
      .$promise.then(function(response) {
        vm.apartments = response.rows;
        vm.apartmentsCount = response.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lägenheter.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  vm.formatNumber = formatNumber;

  function formatNumber(num) {
    if (!num) {
      return 'Ej angivet';
    }
    return $filter('number')(num, 0);
  }

  /**
   * Gets price class
   * @property {number}
   * @property {number}
   * @returns {string} css class
   */
  vm.priceColour = (newPrice, currentPrice) => {
    let colour = 'price-same';
    if (!newPrice) {
      return colour;
    }
    if (newPrice > currentPrice) {
      colour = 'price-up';
    } else if (currentPrice > newPrice) {
      colour = 'price-down';
    }
    return colour;
  };
}

})();
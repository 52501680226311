(function () {
'use strict';

angular.module('hyresgastApp').directive('hyArtifacts', hyArtifacts);

function hyArtifacts($timeout, $location,
  Artifacts,
  HyUtils,
  ValidFileTypes, CommonMessages, 
  Auth ) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/artifacts/artifacts.html',
    scope: {
      objectId: '=',
      objectType: '=',
      fileLabels: '=',
      group: '=',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {

      scope.Auth = Auth;
      scope.artifacts = [];

      let sortArtifacts = function() {
        let maybeInvert = result => scope.sortDirection === 'ASC' ? -result : result;
        let fn;
        switch (scope.sortColumn) {
          case scope.sortColumns[0].column:
            fn = (a, b) => maybeInvert(b.date - a.date);
            break;
          default:
          case scope.sortColumns[1].column:
            fn = (a, b) => maybeInvert(('' + a.label.name).localeCompare(b.label.name) || (b.date - a.date));
            break;
        }
        scope.artifacts.sort(fn);
      };

      scope.sortColumns = [
        {
          title: 'Datum',
          column: 'S1'
        },
        {
          title: 'Typ och datum',
          column: 'S2'
        }
      ];
      scope.sortColumn = scope.sortColumns[1].column;
      scope.sortDirection = 'DESC';
      scope.$on('$routeUpdate', () => {
        if ($location.search().order) {
          sortArtifacts();
          scope.getArtifactsVirtual();
        }
      });

      const fall = { id: -1, name: 'Alla' };
      scope.offset = 0;
      scope.limit = 15;
      scope.filters = { filename: '', label: fall };
      scope.getArtifactsVirtual = function () {
        scope.artifactsFiltered = scope.artifacts
          .filter(x => scope.filters.filename.length ? x.filename.toLowerCase().includes(scope.filters.filename) : true)
          .filter(x => scope.filters.label === fall ? true : x.label === scope.filters.label);
        scope.artifactsVirtual = scope.artifactsFiltered.slice(scope.offset, scope.offset + scope.limit);
        scope.offset = 0;
      };
      scope.changeFilterTimed = function () {
        $timeout(scope.getArtifactsVirtual, 200);
      };

      scope.getArtifacts = function () {
        Artifacts.list({
          objectType: scope.objectType,
          objectId: scope.objectId,
        }).$promise.then(response => {
          scope.artifacts = response.map(x => ({
            date: new Date(x.creationTime),
            filename: decodeURIComponent(x.metadata.filename),
            label: x.metadata.label ? scope.fileLabels.find(l => l.id === parseInt(x.metadata.label)) : '',
            uuid: x.name,
          }));
          sortArtifacts();
          scope.getArtifactsVirtual();
          if (scope.group) {
            scope.artifacts.forEach((el, index) => {
              el.groupBreak = (index === 0 || el.label !== scope.artifacts[index-1].label);
            });
          }
        });
      };

      scope.$watch('fileLabels', newValue => {
        scope.fileLabelsWithAll = [fall].concat(newValue);
      });

      scope.upload = () => {
        let settings = {
          title: 'Ladda upp ett dokument',
          uploadUrl: `/api/artifacts/${scope.objectType}/${scope.objectId}`,
          validFileTypes: ValidFileTypes,
          types: scope.fileLabels,
          typeTitle: 'Välj typ av underlag'
        };
        HyUtils.showDefaultModal('FileUploadExModalCtrl',
          'components/file-upload-ex-modal/file-upload-ex-modal.html',
          // eslint-disable-next-line no-unused-vars
          { settings }, result => {
            scope.getArtifacts();
          }, null, null, { size: 'sm' });
      };

      scope.download = item => {
        Artifacts.download({
          objectType: scope.objectType,
          objectId: scope.objectId,
          uuid: item.uuid,
        }).$promise.then(response => {
          HyUtils.spitOutAttachment(response.data, item.filename);
        });
      };

      scope.destroy = item => {
        HyUtils.showDefaultModal('DeletionConfirmationModalCtrl',
          'components/modal/confirmation/deletionConfirmationModal.html',
          { message: CommonMessages.FILE_DELETE_CONFIRMATION },
          // eslint-disable-next-line no-unused-vars
          result => {

            Artifacts.destroy({
              objectType: scope.objectType,
              objectId: scope.objectId,
              uuid: item.uuid,
            // eslint-disable-next-line no-unused-vars
            }).$promise.then(response => {
              scope.getArtifacts();
            });
          }, null, null, { size: 'md' });
      };

      scope.getArtifacts();
    }
  }
  return directive;
}
})();